import React, { FunctionComponent, useState, useEffect } from 'react';

import Layout from '../../layouts/Layout';
import Card from '../../components/Card';
import MobileAppDownload from '../../components/MobileAppDownload';
import { LandingPageStyle } from './LandingStyle';
import { locationStaticData } from './locationUtils';
import { commentData } from './comments';
import { css } from '@emotion/core';
import { isMobileOnly } from 'react-device-detect';
import { Helmet } from 'react-helmet';
import CookieConsent from 'react-cookie-consent';

export interface CommentData {
  message: string,
  author: string,
  image: string
}

const LandingPage: FunctionComponent = () => {
  const [bulletId, setBulletId] = useState<string>('');
  const [cardId, setCardId] = useState<string>('');
  const [comment, setComment] = useState<CommentData>(commentData[0]);
  var position = 0;

  useEffect(() => {
    setCardId('card__1');
    setBulletId('bullet__1');

    const timer = setInterval(() => {
      position = position + 1;
      if (position >= commentData.length) {
        position = 0;
      }
      changeComment(position);
    }, 20000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  const onHoverSlogan = (id: string, position: number) => {
    if (isMobileOnly) {
      return;
    }
    setCardId(id);
    position = position;
    changeComment(position);
  };

  const changeComment = (position: number) => {
    setComment(commentData[position]);
    setBulletId(`bullet__${position + 1}`);
  }

  const changeCommentDot = (event: any) => {
    let position = event.target.id.replace(/\D/g, '');
    changeComment(parseInt(position) - 1);
  }

  return (
    <Layout isLandingPage>
      <LandingPageStyle>
        <Helmet>
          <meta charSet="utf-8" />
          <title>TravelPix</title>
        </Helmet>
        <CookieConsent
          style={{ background: "#FAFAFA", color: "#8D929D", paddingLeft: "16px", paddingRight: "100px", fontSize: "12px" }}
          contentStyle={{ margin: "2px", paddingTop: "8px", paddingBottom: "8px" }}
          buttonStyle={{ color: "#1E2330", fontSize: "12px", backgroundColor: "#FBCA00", padding: "8px", margin: "4px" }}
        >
          We use cookies to offer you a better experience, analyse our traffic, and personalise content and advertising. Learn about how we use cookies and how you can control them by viewing our <a className="cookie-a" href="/cookie-policy" target="_blank">Cookies Policy</a>
        </CookieConsent>
        <p className="service--title">How it works</p>
        <section className="service--group">
          <div className="service service--1">
            <span>1</span>
            <img src="/intro-service/1.png" alt="Ads 1" />
            <p>Head over to one of our hand-picked spots for great photos of your trip</p>
          </div>
          <div className="service service--2">
            <span>2</span>
            <img src="/intro-service/2.png" alt="Ads 2" />
            <p>A TravelPix Photographer will snap your best angles</p>
          </div>
          <div className="service service--3">
            <span>3</span>
            <img src="/intro-service/3.png" alt="Ads 3" />
            <p>View, buy and keep the ones you love on any device</p>
          </div>
        </section>
        <div className="join--now">
          <p>
            Great photos for every trip. &nbsp;
            <a onClick={() => document.getElementById("popularLocation")?.scrollIntoView()}>View our locations</a>
          </p>
          <MobileAppDownload className="mobile--download" />
        </div>

        <section className="about-us">
          <div className="about-us-container">
            <div className="about-note">
              <p className="service--title">About us</p>
              <p className="about-note-p">
                Travel and Photos are a natural fit. Capturing memories with family &amp; friends is a big part of our holidays, and we think it
                should be easy to get great photos. Enough of selfie-sticks, having to ask strangers or not being able to include everyone
                in the photo. Our mission is simple: We want to offer everyone great professional photos at epic locations for a fair price.
              </p>
              <p className="about-note-p">
                TravelPix is a team of friendly professional photographers ready to capture your best side at landmarks and popular spots in
                London. No need to plan, reserve or commit. We know the best backdrops, angles and poses and will capture the best memories
                of your trip.
              </p>
              <p className="about-note-p">
                Have your photo taken for free with no commitment and see if you like it. If you do, and we are confident you will - great -
                it’s super easy to order. If you don’t - no hard feelings. We know how much love there is out there for the #selfie.
              </p>
            </div>

            <div className="about-pic">
              <img src="/landing-page/about-us.png" alt="about-us" />
            </div>
          </div>
        </section>

        <section className="popular--location" id="popularLocation">
          <div className="locations">
            <div className="location-title main-title">Locations</div>
            <p className="location-note">
              People come from all over the world to visit London's most iconic landmarks. All our locations are carefully handpicked to
              ensure fantastic photo ops and high footfall. They include epic historical landmarks, beautiful scenic backdrops and local
              "hidden" gems. Here are a few of our favourites.
            </p>
          </div>
          <div className="location-title ">Our popular locations</div>
          <div className="popular-location-cards">
            {locationStaticData.map((location: any) => (
              <div className="location-card" key={location.title}>
                <img src={location.img} alt={location.title} />
                <div className="location-title">{location.title}</div>
                <div className="location-note">{location.note}</div>
              </div>
            ))}
          </div>
        </section>
        {/* <MobileAppDownload className="join--now--mobile" /> */}
        <section className="side__slider">
          <div className="side__wrapper">
            <div className="left__slider">
              <div className="reason--using">
                <p className="title">
                  <span>Join the TravelPix</span> <br />
                  <span>community</span>
                </p>
                <Card className={cardId === 'card__1' ? 'card__items active' : 'card__items'} id="card__1" position={0} onHover={onHoverSlogan}>
                  <img src="/slider-intro/professional-quality.svg" alt="Professional Quality" />
                  <p>
                    <span>Professional photos</span> at the most popular sights - and get everyone in the picture
                  </p>
                </Card>
                <Card className={cardId === 'card__2' ? 'card__items active' : 'card__items'} id="card__2" position={1} onHover={onHoverSlogan}>
                  <img src="/slider-intro/family-into-photos.svg" alt="Family Photos" />
                  <p>
                    <span>No up front fee!</span> Only buy the photos if you love them
                  </p>
                </Card>
                <Card className={cardId === 'card__3' ? 'card__items active' : 'card__items'} id="card__3" position={2} onHover={onHoverSlogan}>
                  <img src="/slider-intro/share-in-mobile.svg" alt="Sharing on mobile" />
                  <p>
                    <span>Easy &amp; Flexible</span> check out where we are and come by to have your photos taken
                  </p>
                </Card>
                <MobileAppDownload
                  className="mobile--download"
                  css={css`
                    padding-top: 20px;

                    @media screen and (max-width: 768px) {
                      display: none !important;
                    }
                  `} />
              </div>
            </div>
            <div className="right__slider">
              <div className="photo__slider">
                <img
                  src={commentData[0].image}
                  alt='Tourist-testimonial-1'
                  className={bulletId === 'bullet__1' ? 'photo active' : 'photo'} />
                <img
                  src={commentData[1].image}
                  alt='Tourist-testimonial-2'
                  className={bulletId === 'bullet__2' ? 'photo active' : 'photo'} />
                <img
                  src={commentData[2].image}
                  alt='Tourist-testimonial-3'
                  className={bulletId === 'bullet__3' ? 'photo active' : 'photo'} />
              </div>
              <div className="group--text">
                <p>{comment.message}</p>
                <p>{comment.author}</p>
              </div>
              <div className="bullet__slider">
                <span id={'bullet__1'} className={bulletId === 'bullet__1' ? 'bullet active' : 'bullet'} onClick={changeCommentDot} />
                <span id={'bullet__2'} className={bulletId === 'bullet__2' ? 'bullet active' : 'bullet'} onClick={changeCommentDot} />
                <span id={'bullet__3'} className={bulletId === 'bullet__3' ? 'bullet active' : 'bullet'} onClick={changeCommentDot} />
              </div>
            </div>
          </div>
        </section>
      </LandingPageStyle>
    </Layout >
  );
};

export default LandingPage;
