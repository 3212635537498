export const commentData = [
  {
    message: "\"Finally a great photo of the whole family! Big Ben was in the background and the quality was so professional!\"",
    author: "Arabella, New York",
    image: "/landing-page/tourist-testimonial-1.png"
  },
  {
    message: "\"My smartphone photos were just not good enough for those special holiday memories...\"",
    author: "Vanessa, Shanghai",
    image: "/landing-page/tourist-testimonial-2.png"
  },
  {
    message: "\"Why has this not existed before? My wife and I love to travel with our kids but can’t stand cliche tourist photos and terrible selfie poses. Definitely worth using TravelPix instead.\"",
    author: "Erik, Toronto",
    image: "/landing-page/tourist-testimonial-3.png"
  }
];
