import styled from '@emotion/styled';

const LandingPageStyle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 0 auto;
  padding-top: 85px;
  .btn--control {
    padding: 12px 2.4em !important;
  }
  .service--title {
    font-size: 20px;
    font-family: RubikMedium;
    text-align: center;
    margin: 0;
    line-height: 24px;
  }
  .service--group {
    margin-top: 52px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    max-width: 55.5625rem;
    width: 100%;
    img {
      width: 100%;
      max-width: 11.6875rem;
    }
    .service {
      max-width: 12rem;
      width: 100%;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      p {
        font-size: 15px;
        line-height: 24px;
        text-align: center;
        font-family: RubikMedium;
      }
      &.service--1 {
        span {
          color: #fbca00;
        }
      }
      &.service--2 {
        span {
          color: #5e7ece;
        }
      }
      &.service--3 {
        span {
          color: #ff695a;
        }
      }
      span {
        position: absolute;
        left: 0;
        top: -10px;
        font-size: 30px;
        font-family: RubikMedium;
        line-height: 1.23;
      }
    }
  }

  .about-us {
    width: 100%;
    padding: 72px 0;
    background-color: #fafafa;
    display: flex;
    justify-content: center;
    align-items: center;

    .about-us-container {
      display: flex;

      max-width: 55.5625rem;
      .service--title {
        text-align: left;
      }
      .about-note {
        width: 50%;

        .about-note-p {
          font-family: RubikRegular;
          color: #a5a7ad;
          font-size: 15px;
          line-height: 20px;
        }
      }
      .about-pic {
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          max-width: 313px;
          height: auto;
        }
      }
    }
  }

  .popular--location {
    display: flex;
    width: 100%;
    max-width: 933px;
    flex-direction: column;
    align-items: center;
    margin: 70px auto;

    .location-title {
      font-family: RubikMedium;
      font-size: 20px;
      line-height: 24px;
    }

    .main-title {
      text-align: center;
      margin-bottom: 1rem;
    }

    .locations p {
      text-align: center;
      max-width: 420px;
      margin:0 30px 50px;
    }

    .location-note {
      font-family: RubikRegular;
      color: #a5a7ad;
      font-size: 15px;
      line-height: 20px;
      margin-top: 5px;
      margin
    }

    .popular-location-cards {
      display: flex;
      width: 100%;
      justify-content: space-between;

      .location-card {
        width: 33.3%;
        max-width: 277px;
        img {
          margin: 30px 0;
          width: 100%;
          height: auto;
        }
      }
    }

    @media screen and (max-width: 768px) {
      .locations {
        display: none;
      }
    }
  }

  .join--now {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 1.6rem;
    margin-top: 2.5rem;

    p {
      margin-bottom: 1.5rem;
      font-family: RubikMedium;
      a {
        text-decoration: none;
        color: #fbca00;
      }
    }

    @media screen and (max-width: 768px) {
      margin-bottom: 0;
      margin-top: 0;
      max-width: 300px;

      .mobile--download {
        padding-bottom: 0;
      }
    }

    @media screen and (max-width: 425px) {
      width: 260px;
      font-size: 20px;

      .mobile--download {
        padding-bottom: 0;
      }
    }
  }

  .side__slider {
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-color: #fafafa;
    flex: 1 0 auto;
    width: 100%;
    position: relative;
    padding: 4.38rem 0 5rem;

    .side__wrapper {
      display: flex;
      flex-direction: row;
      max-width: 1330px;
      width: 100%;
      margin-left: -12%;
    }
    .left__slider {
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;
      flex: 5;
      position: relative;
      .reason--using {
        max-width: 28.125rem;
        width: 100%;
        z-index: 1;
        transform: translateX(6%);
        p.title {
          font-size: 44px;
          transform: translateX(10%);
          text-align: left;
        }
        .card__items {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          max-width: 26.875rem;
          width: 100%;
          img {
            max-width: 54px;
            width: 100%;
            max-height: 44px;
            height: 100%;
            margin-right: 2rem;
          }
          p {
            font-size: 18px;
            line-height: 1.4444;
          }
        }
        .mobile--download {
          justify-content: flex-start;
          margin-top: 2rem;
          transform: translateX(10%);
        }
      }
    }
    .right__slider {
      display: flex;
      flex: 5;
      position: relative;
      color: #fff;
      background-position: center;
      background-size: cover;
      max-width: 665px;
      max-height: 675px;

      .group--text {
        position: absolute;
        bottom: 20%;
        left: 11.87%;

        p {
          margin: 0;
          max-width: 408px;
          font-family: RubikRegular;

          &:first-of-type {
            font-size: 24px;
            line-height: 1.1875;
            font-family: RubikBoldItalic;
          }
          &:last-of-type {
            font-size: 16px;
            line-height: 3.07;
            font-family: RubikMedium;
          }
        }
      }
      .bullet__slider {
        position: absolute;
        bottom: 8%;
        left: 11.87%;
        display: flex;
        flex-direction: column;
        z-index: 1;

        .bullet {
          height: 0.7em;
          width: 0.7em;
          background: #fff;
          border-radius: 50%;
          margin: 0.5em 0;
          opacity: 0.5;
          &.active {
            opacity: 1;
          }
        }
      }
      .photo__slider {
        position: absolute;
        top: 0%;
        left: 0%;
        max-width: 100%;
        max-height: 100%;
        width: 100%;
        height: 100%;

        .photo {
          position: absolute;
          width: 100%;
          height: 100%;
          opacity: 0;
          transition: opacity 0.5s linear;
          object-fit: cover;

          &.active {
            transition: opacity 0.5s linear;
            opacity: 1;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 1120px) {
    padding-top: 13.625rem;
    .service--group {
      padding-left: 1rem;
      padding-right: 1rem;
    }

    .popular--location {
      .popular-location-cards {
        .location-card {
          max-width: 250px;
        }
      }
    }

    .side__slider {
      .left__slider {
        width: 100%;
        padding-left: 2%;
        padding-right: 2%;
        justify-content: center;
        .reason--using {
          transform: translateX(16%);
          p.title {
            transform: translateX(8%);
          }

          .card__items {
            flex-direction: column;
            text-align: center;
            margin: 20px 0;
            padding: 10px 10px;
            img {
              margin-right: 0rem;
            }
            p {
              padding: 0 1.5rem;
            }
          }
        }
        .title {
          margin: 0;
          text-align: center;
        }
      }
      .right__slider {
        .group--text {
          max-width: 60%;
          left: 50%;
          transform: translateX(-50%);
        }

        .bullet__slider {
          left: 25%;
        }
      }

      .mobile--download {
        display: block;
        transform: translateX(0);
      }
    }
  }

  @media only screen and (max-width: 812px) {
    .side__slider {

      .left__slider {
        width: 100%;
        padding-left: 2%;
        padding-right: 2%;
        justify-content: center;

        .reason--using {
          transform: translateX(10%);

          p.title {
            transform: translateX(0%);
          }
        }
      }
    }
  }

  @media only screen and (max-width: 768px) {
    .service--group {
      margin-top: 10px;
      flex-direction: column;
      align-items: center;
      .service {
        margin-top: 2rem;
        p {
          line-height: 1.6;
        }
      }
    }

    .popular--location {
      .popular-location-cards {
        flex-direction: column;
        align-items: center;
        .location-card {
          max-width: 357px;
          width: 50%;
        }
      }
    }

    .about-us {
      margin-top: 72px;
      .about-us-container {
        flex-direction: column-reverse;
        .service--title {
          text-align: center;
        }
        .about-note {
          width: 100%;
          padding: 72px 30px 0;

          .about-note-p {
            font-family: RubikRegular;
            color: #a5a7ad;
            font-size: 15px;
            line-height: 20px;
          }
        }
        .about-pic {
          margin-top: -72px;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            max-width: 768px;
            width: 100%;
            height: auto;
          }
        }
      }
    }

    .side__slider {
      padding-bottom: 0;

      .side__wrapper {
        flex-direction: column;
        margin-left: 0%;
      }
      margin-top: 30px;
      flex-direction: column;
      align-items: center;
      .left__slider {
        .reason--using {
          transform: translateX(0%);

          .card__items {
            opacity: 1;
            padding: 10px 5px;
            box-shadow: none;
            background: #fafafa;
          }
          .active {
            background: #fafafa;
          }
          p.title {
            font-size: 24px;
            line-height: 1.16666;
            text-align:center;
            transform:translateX(0);
          }
        }
      }
      .right__slider {
        padding: 72% 50%;

        .group--text {
          top: 36%;
          left: 50%;
          text-align: center;
        }

        .bullet__slider {
          top: 12%;
          left: 50%;
        }
      }
    }
  }

  .cookie-a {
    color: #8D929D;
  }

  @media screen and (max-width: 425px) {
    padding-top: 190px;
    .popular--location {
      .popular-location-cards {
        flex-direction: column;
        align-items: center;
        .location-card {
          padding: 0 30px;
          width: 100%;
          max-width: 100%;
        }
      }
    }

    .about-us {
      .about-us-container {
        .service--title {
          text-align: left;
        }
      }
    }

    .side__slider {

      .side__wrapper {
        margin-left: 0%;
      }

      .left__slider {
        .reason--using {
          transform: translateX(0%);
          width: auto;

          .card__items {
            opacity: 1;
            box-shadow: none;
            background: #fafafa;

            p {
              width: 240px;
              padding: 10px;
            }
          }
          .active {
            background: #fafafa;
          }
        }
      }
      .right__slider {
        .group--text {
          min-width: 241px;
          p {
            &:first-of-type {
              font-size: 18px;
              line-height: 1.6666;
              text-align: center;
            }
            &:last-of-type {
              text-align: center;
              font-size: 16px;
              line-height: 2.307;
              font-family: RubikMedium;
              text-align: center;
            }
          }
        }
      }
    }
  }
`;

export { LandingPageStyle };
